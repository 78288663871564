import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Zap from '../zap.png';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import PlayCircleFilledRoundedIcon from '@material-ui/icons/PlayCircleFilledRounded';
import MPopover from "./Popover"




const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginLeft: 0,
    padding: "14px 14px 14px 14px",
  },
  navitem: {
    color: "#333D43",
    textTransform: 'lowercase',
    marginTop: theme.spacing(2),
    borderRadius: 50,
    marginLeft: 8
  },
  title: {
    flexGrow: 1,
    fontSize: 20,
    color: '#000000',
    fontWeight: 900,
    marginTop: theme.spacing(2),
  },
  tool: {
    backgroundColor: 'rgba(65, 20, 190, 0.29)'
  }
}));

const NavBar = ({home}) => {
  const classes = useStyles();
  let history = useHistory();

  return (
    <div className={classes.root}>
      <AppBar position="static" elevation={0} color="transparent" style={{paddingRight: 25}}>
        <Toolbar>
          <IconButton onClick={() => {history.push("/")}} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                <img src={Zap} height="20px" width="20px" alt=""/>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            
          </Typography>
          {home ?  
            <React.Fragment>
              <MPopover 
              content={<b>demo</b>}
              component={
                   <IconButton edge="start" onClick={() => {history.push("demo")}} className={classes.navitem} color="inherit" aria-label="menu">
                   <PlayCircleFilledRoundedIcon/>
                 </IconButton>
              }/>
             
              <IconButton edge="start" onClick={() => {history.push("add")}} className={classes.navitem} color="inherit" aria-label="menu">
                <LocationOnRoundedIcon/>
              </IconButton>
            </React.Fragment>

          : 
              <IconButton edge="start" onClick={() => {history.push("/")}} className={classes.navitem} color="inherit" aria-label="menu">
                <HomeRoundedIcon/>
              </IconButton>
            }
        </Toolbar>
      </AppBar>
    </div>
  );
}


export default NavBar;