import React from 'react';
import TextLoop from "react-text-loop";
import Content from '../components/Content'
import Navbar from '../components/NavBar'


export default function HomePageContent() {

  return (
    <div>
    <Navbar home={true} />
    <Content title={
      <div>
        <b><span style={{color: '#551DEF'}}>chatter,</span>
        <br/>
        where every place <br/>
        <TextLoop springConfig={{ stiffness: 180, damping: 8 }} mask={true} 
          fade={false} interval={2000} children={["on campus", "in town"]}>
        </TextLoop>  <br/>
        has a group chat. </b>
       </div>
    }
    content={
      <div>
        with so many places to go to, <b>casual communities</b> are forming everywhere. 
        study halls, gyms, malls, events, coffee shops, and on campus, etc. <b>everywhere. </b>
        when we come together as a casual community we have an opportunity 
        to interact & talk. but we don't. we plan to change that.
        <br/>
        <br/>
        <b>we believe it should be effortless to talk to everyone around you. </b> 
        as a result, we are building chatter, a location-based group chat app. 
        <br/>
        <br/>
        chatter will be a <b>network of group chats, attached to all the meaningful 
        areas on your campus or in your town.</b> you automatically join the group 
        chat of the area you are in, 
        and can start talking to people around you. 
        <br/>
        <br/>
        <br/>
        <b>we are launching soon in stellenbosch, south africa. </b> if you want to get in touch, or stay updated on when we launch, send us a mail at <b><a style={{color: '#FEAC33', textDecoration: 'none'}} href="mailto:info@thechatter.xyz">info@thechatter.xyz</a></b>
      </div>
    } />
    </div>
  );
}
