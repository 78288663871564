import React from 'react';
import TextLoop from "react-text-loop";
import Content from '../components/Content'


import Navbar from '../components/NavBar'

export default function AddPageContent({title, content}) {

  return (
    <div>
    <Navbar home={false}/>
    <Content title={
      <div>
        <b>add your <br/>
        <TextLoop springConfig={{ stiffness: 180, damping: 8 }} mask={true} 
          fade={false} interval={2000} children={["shop", "store", "bar", "area", "event", "cafe"]}>
        </TextLoop>  <br/>
        to chatter. </b>
       </div>
    }
    content={
      <div>
        coming soon...
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

        <br/>
      </div>
    } />
    </div>
  );
}
