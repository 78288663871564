import React,  { Fragment } from "react";
import { Route, Switch} from "react-router-dom";
import HomePageContent from "./containers/HomePageContent"
import AddPageContent from "./containers/AddPageContent"
import FourOFour from './containers/FourOFour'
import DemoPage from './containers/DemoPage'
import FAQPage from './containers/FAQPage'

import { BrowserRouter as Router } from "react-router-dom";


function Root() {
  return (
      <Router>
          <Fragment>
            <Switch>
                <Route exact path="/faq" component={FAQPage}/>
                <Route exact path="/demo" component={DemoPage}/>
                <Route exact path="/" component={HomePageContent}/>
                <Route exact path="/add" component={AddPageContent}/>
                <Route exact path="*" component={FourOFour}/>
            </Switch>
        </Fragment>
      </Router>
  );
}

export default Root;