import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TwitterIcon from '@material-ui/icons/Twitter';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';




const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 64,
    },
    marginTop: 48,
    overflowWrap: 'break-word',
    display: 'inline-block',
    lineHeight: '1.2em',
    color: "#333D43"
  },
  navitem: {
    color: '#333D43',
    marginTop: theme.spacing(2),
    borderRadius: 50,
    marginRight: theme.spacing(2),
    //padding: "8px 8px 8px 8px",
  },
  content: {
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 22,
      },
      color: '#333D43',
      marginTop: theme.spacing(2),
      marginBottom: 160,
  },
  gridcontainer: {
    [theme.breakpoints.down('sm')]: {
      width: '80%', 
    },
    [theme.breakpoints.up('md')]: {
      width: '65%',
    },
    margin: '0 auto'
  }
  

}));


export default function Content({title, content}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container className={classes.gridcontainer}>
        <Grid item xs={12} lg={10} >
        <Typography  className={classes.title}>
           {title}
        </Typography>
        </Grid>
        <Grid item xs={12} lg={10} >
            <IconButton edge="start" onClick={() => {  window.open('mailto:info@thechatter.xyz'); }} 
              className={classes.navitem} color="inherit" aria-label="menu">
              <EmailRoundedIcon/>
            </IconButton>
            <IconButton edge="start" onClick={() => {  window.open('https://twitter.com/getmorechatter'); }} 
              className={classes.navitem} color="inherit" aria-label="menu">
              <TwitterIcon/>
            </IconButton>
        </Grid>
        <Grid item xs={12} lg={10}>
          <Typography className={classes.content}>
            {content}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
