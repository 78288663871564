import YouTube from 'react-youtube';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Content from '../components/Content'
import Navbar from '../components/NavBar'




export default function HomePageContent() {

  return (
    <React.Fragment>
        <Navbar home={false}/>
        <Content
        title={
            <b>chatter demo</b>
        }
        content={
            <YouTube  videoId="qzO6CjsS3FM"/>
        } />
        <Grid container 
            direction="column"
            alignItems="center"
            justify="center" 
            style={{marginTop: 48, marginBottom: 48}}>
        <Grid item  align="center">
           
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
