import YouTube from 'react-youtube';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Content from '../components/Content'
import Navbar from '../components/NavBar'




export default function FAQPage() {

  return (
    <React.Fragment>
        <Navbar home={false}/>
        <Content
        title={
            <b>faq</b>
        }
        content={
            <div>
               <b>What is Chatter?</b><br/>
                Chatter is an app that creates & attaches group chats to all
                the know/important areas in your town. Think online 
                chat rooms, but you can only be in chat room A when
                you are in area A.
               <br/>
               <br/>
               
               <b>What is a Chatter area?</b><br/>
               Chatter areas are locations in your town or on your 
               campus that have group chats attached to them. 
               Everyone in an area is in the same group chat 
               and can talk to one another. 
               <br/>
               <br/>

               <b>Can I talk to people in other areas?</b><br/>
               Unfortunately, no. To keep conversations in the group 
               chats relevant to the areas they are attached 
               to, you can only talk to the people in the same area as you.
               <br/>
               <br/>

               <b>How is Chatter different from WhatsApp?</b><br/>
               WhatsApp, and other messaging apps, are to chat with people 
               you already know. You would use Chatter to chat with people you don't 
               know but are around you
               every day. Think people in your class, gym or your apartment block.
               <br/>
               <br/>
               <b>If you have any other questions, feel free to send us a mail at <a style={{color: '#FEAC33', textDecoration: 'none'}} href="mailto:info@thechatter.xyz">info@thechatter.xyz</a></b>
            </div>
        } />
    </React.Fragment>
  );
}
