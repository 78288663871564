import React from 'react';
import TextLoop from "react-text-loop";
import Content from '../components/Content'
import Navbar from '../components/NavBar'


export default function HomePageContent() {

  return (
    <div>
    <Navbar home={false} />
    <Content title={
      <div>
          <b>
         <TextLoop springConfig={{ stiffness: 180, damping: 8 }} mask={true} fade={false} interval={2000} children={["no. ", 
                                                                                                                    "nope. ", 
                                                                                                                    "wrong place. ", 
                                                                                                                    "wrong door. ", 
                                                                                                                    "you took a wrong turn. ", 
                                                                                                                    "nay. ", 
                                                                                                                    "noooope. ", 
                                                                                                                    "wrong. ", 
                                                                                                                    "oops.", 
                                                                                                                    "somthing went wrong.", 
                                                                                                                    "404.",
                                                                                                                    "not found.", 
                                                                                                                    "no page here.",
                                                                                                                    "sorry, not home." ]}>
                    </TextLoop>
                    </b>
                    <br/>
                    <b><a style={{color: '#FEAC33', textDecoration: 'none'}} href="/">go home.</a></b>   
       </div>
    }
    content={
      <div>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>

      </div>
    } />
    </div>
  );
}
